<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">FILE FORMS DELETION LIST</h3>
      <div>
        <el-input v-model="id" placeholder="Search ID">
          <el-button slot="append" type="primary" @click="getFiles">Search</el-button>
        </el-input>
      </div>
    </div>
    <el-table :data="data" border class="hidden-sm-and-down">
      <el-table-column label="FILE NO" prop="id"></el-table-column>
      <el-table-column label="APPLICATION TYPE" prop="name"></el-table-column>
      <el-table-column label="EMPLOYEE" prop="em_id_name"></el-table-column>
      <el-table-column label="DATES COVERED" prop="date_dates">
        <!-- <template slot-scope="props">
          {{ formatDate(props.row.date_added) }}
        </template> -->
      </el-table-column>
        <el-table-column label="ACTIONS" fixed="right" width="170">
          <template slot-scope="scope">
            <div class="fixed-row" v-if="scope.row.a_id === '2'">
              <!-- <el-tooltip class="item" effect="dark" content="Approve" placement="top-start">
                <el-button size="mini" type="success" @click="confirmApprove(scope.row, 'Registration Approved')"><i class="el-icon-check"></i></el-button>
              </el-tooltip> -->
              <el-tooltip class="item" effect="dark" content="Cancel Request" placement="top-start">
                <el-button size="mini" type="danger" @click="confirmCancel(scope.row.id)"><i class="el-icon-close"></i></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'EmployeeFileList',
  data () {
    return {
      data: [],
      id: null
    }
  },
  methods: {
    getFiles () {
      this.$http
        .get(`hr.form-list/${this.id}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body : []
        })
    },
    confirmCancel () {
      this.$confirm('Are you sure you want to cancel this application?', 'info', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          const form = {
            a_id: 1,
            id: this.id
          }
          this.$http
            .post('hr.forms/update', form)
            .then(() => {
              this.$message({
                type: 'success',
                message: 'File Application cancel successfully'
              })
              this.dialogView = false
              this.getFiles()
            })
        })
    }
  }
}
</script>
